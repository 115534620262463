import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { CardFooter, FormTitle, FormSubtitle, Button } from "@impero-com/ui";
import { Input } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";


import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import { APIRoute } from "routes";
import { RedirectTarget } from "types/web/global/login_flow";
import { AjaxManager } from "utils/ajax";
import { t } from "utils/i18n";


import RightSideText from "./rightSideText";

const FormItem = Form.Item;

class TeamLogin extends React.Component<TeamLoginProps, TeamLoginState> {
    constructor(props: TeamLoginProps) {
        super(props);
        this.state = {
            status: { type: "DEFAULT" },
        };
    }

    handleSubmit = (e: React.FormEvent<any>) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: TeamLoginProps) => {
            if (!err) {
                let { organization } = values;
                if (organization === undefined) {
                    throw new Error(t("Login_OrgUndefinedError"));
                }
                organization = organization.toLowerCase();
                const { ajaxManager } = this.props;
                ajaxManager.ajax<RedirectTarget, TeamLoginStatus>(
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    () => axios.post(`${APIRoute.ROOT}/enter-org`, { "org_short_name": organization }), {
                        component: this,
                        initialStatusValue: "DEFAULT",
                        getErrorStatus: () => ({ type: "DEFAULT" }),
                        getSuccessStatus: () => ({ type: "DEFAULT" }),
                        inFlightStatus: { type: "LOGGING_IN" },
                        onErrorCode: {
                            404: () => {
                                this.props.form.validateFields((err: any) => {
                                    if (!err) {
                                        this.props.form.setFields({
                                            organization: {
                                                value: organization,
                                                errors: [new Error(t("Login_OrgNotRecognizedError"))],
                                            },
                                        });
                                    }
                                });
                            },
                        },
                    })
                    .then((response: RedirectTarget) => {
                        const { redirect } = response;
                        window.location.href = redirect;
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <FormCardContainer rightSide={<RightSideText />}>
                <FormTitle title={t("Login_SignInOrganization")} />
                <FormSubtitle subtitle={t("Login_SignInOrgDescription")} />
                <FormCard onSubmitForm={this.handleSubmit}>
                    <FormItem>
                        {getFieldDecorator("organization", {
                            rules: [{ required: true, message: t("LoginFlow_PleaseEnterOrganizationName") }],
                        })(
                            <Input addonAfter=".impero.com" placeholder={t("Login_YourOrganization")} />
                        )}
                    </FormItem>
                    <FormItem>
                        <Button
                            id="loginFormSubmitBtn"
                            type="primary"
                            htmlType="submit"
                            loading={this.state.status.type === "LOGGING_IN"}
                            block>
                            {t("Login_ContinueButton")}
                        </Button>
                    </FormItem>
                </FormCard>

                <CardFooter>
                    <Link to="/forgot">{t("LoginFlow_ForgotYourTeamName")}</Link>
                </CardFooter>
            </FormCardContainer>
        );
    }
}

const TeamLoginWrapped = Form.create<TeamLoginProps>()(TeamLogin);

export default TeamLoginWrapped;

interface TeamLoginProps extends FormComponentProps {
    organization?: string,
    ajaxManager: AjaxManager,
}

type TeamLoginStatus = { type: "DEFAULT" } | { type: "LOGGING_IN" };
interface TeamLoginState {
    status: TeamLoginStatus,
}
