import * as React from "react";

import Form from "@ant-design/compatible/es/form";
import { UserOutlined } from "@ant-design/icons";
import { CardFooter, FormSubtitle, FormTitle, Button } from "@impero-com/ui";
import { Input } from "antd";

import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import { isEmailValid } from "utils/form";
import { t } from "utils/i18n";

import RightSideText from "./rightSideText";
import theme from "./theme.module.scss";

const FormItem = Form.Item;

const ForgotPassword = (props: any) => {
    const { getFieldDecorator } = props.form;
    return (
        <FormCardContainer rightSide={<RightSideText />}>
            <FormTitle title={t("LoginFlow_ForgotYourPassword")} />
            <FormSubtitle subtitle={t("LoginFlow_ResetPasswordEmail")} />
            <FormCard>
                <FormItem>
                    {getFieldDecorator("email", {
                        rules: [{
                            required: true,
                            message: t("LoginFlow_InputCorrectEmail"),
                        }, {
                            validator: (_rule: any, value: string | undefined, callback: any) => {
                                if (!isEmailValid(value)) {
                                    callback(t("LoginFlow_InputCorrectEmail"));
                                } else {
                                    callback();
                                }
                            },
                        }],
                    })(
                        <Input prefix={<UserOutlined className={theme.iconOpacity} />} placeholder={t("Login_UserName")} />
                    )}
                </FormItem>
                <FormItem>
                    <Button
                        id="loginFormSubmitBtn"
                        type="primary"
                        htmlType="submit"
                        block>
                        {t("LoginFlow_SendMeAnEmail")}
                    </Button>
                </FormItem>
            </FormCard>

            <CardFooter>
                <a href="https://impero.com/get-in-touch/" target="_blank">{t("LoginFlow_DontHaveAccount")}</a>
            </CardFooter>
        </FormCardContainer>);
};

export default ForgotPassword;
