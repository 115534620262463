import * as React from "react";

import Form from "@ant-design/compatible/es/form";
import { CardFooter, FormSubtitle, FormTitle, Button } from "@impero-com/ui";
import { Link } from "react-router-dom";

import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import expiredLink from "img/expired-link.svg";
import { t } from "utils/i18n";

const FormItem = Form.Item;

const LinkExpired = () => {
    return (
        <FormCardContainer rightSide={<RightSideIcon icon={expiredLink} customImages={false} />}>
            <FormTitle title={t("LoginFlow_LinkHasExpired")} />
            <FormSubtitle subtitle={t("LoginFlow_LinkHasBeenUsed")} />
            <FormCard>
                <FormItem>
                    <Link to="/forgot">
                        <Button
                            id="loginFormSubmitBtn"
                            type="primary"
                            htmlType="submit"
                            block>
                            {t("LoginFlow_GetNewEmail")}
                        </Button>
                    </Link>
                </FormItem>
            </FormCard>

            <CardFooter />
        </FormCardContainer>);
};

export default LinkExpired;
