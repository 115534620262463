import * as React from "react";

import Form from "@ant-design/compatible/es/form";
import { CardFooter, FormTitle, FormSubtitle, Button } from "@impero-com/ui";
import { Trans } from "react-i18next";
import { Link, } from "react-router-dom";

import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import emailSent from "img/email-sent.svg";
import { t } from "utils/i18n";

const FormItem = Form.Item;

export default class CheckInbox extends React.Component<CheckInboxProps> {
    render() {
        const { email } = this.props.match.params;
        return (
            <FormCardContainer rightSide={<RightSideIcon icon={emailSent} customImages={false} />}>
                <FormTitle title={t("LoginFlow_CheckYourInbox")} />
                <FormSubtitle subtitle="">
                    <Trans i18nKey="LoginFlow_EmailSentWithLink">
                        We have sent an email to <a>{{ email } as unknown as React.ReactNode}</a> with a link to get you back to your organization
                    </Trans>
                </FormSubtitle>
                <FormCard>
                    <FormItem>
                        <Link to="/forgot">
                            <Button
                                id="loginFormSubmitBtn"
                                type="primary"
                                htmlType="submit"
                                block>
                                {t("LoginFlow_WrongEmail")}
                            </Button>
                        </Link>
                    </FormItem>
                </FormCard>

                <CardFooter />
            </FormCardContainer>);
    }
}

interface CheckInboxProps {
    match?: any,
}
