import * as React from "react";

import { FormSubtitle, FormTitle, Button } from "@impero-com/ui";

import imperoScreen from "img/impero-screen.png";
import { t } from "utils/i18n";

import theme from "./theme.module.scss";

const LoginRightText = () => {
    return (
        <div className={theme.loginFormRight}>
            <div>
                <div className={theme.loginRightText}>
                    <FormTitle title={t("LoginFlow_NotYetFamily")} styling={{ maxWidth: 260, color: "#fff" }} />
                    <FormSubtitle styling={{ maxWidth: 380, color: "#fff" }} subtitle={t("LoginFlow_GetContacted")} />
                </div>
                <a href="https://impero.com/get-in-touch/" target="_blank">
                    <Button id="formLoginContactBtn" className={theme.rightSideButton} type="primary" ghost>{t("LoginFlow_GetInTouch")}</Button>
                </a>
            </div>
            <div>
                <img src={imperoScreen} className={theme.loginRightImage} />
            </div>
        </div>);
};

export default LoginRightText;
